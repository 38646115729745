






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

@Component
export default class MyList extends Vue {
  public isLoading = false; // 是否处于下拉刷新中
  public loading = false; // 是否处于上拉加载中
  public finished = false; // 是否完成，完成则不再上拉加载
  public page = 0; // 当前列表页数

  @Prop() private url!: string; // 请求的地址
  @Prop() private data!: Dictionary<string>; // 请求的数据
  @Prop() private len!: number; // 列表长度
  @Prop() private none_type!: string;

  // 请求的数据发送变化，重新请求列表
  @Watch("data")
  changeData(newV: Dictionary<string>, oldV: Dictionary<string>) {
    console.log(newV!=oldV)
    if (JSON.stringify(newV) !== JSON.stringify(oldV)) {
      this.onRefresh();
    }
    // if(newV!==oldV){
    //   this.onRefresh();
    // }
  }
  // 请求的数据发送变化，重新请求列表
  // @Watch("page")
  // changePage(newV: Dictionary<string>, oldV: Dictionary<string>) {
  //   // console.log(newV)
  // }

  getNoneImage() {
    switch (this.none_type) {
      case "goods":
        return require("@/assets/list-none/goods.png");
      case "info":
        return require("@/assets/list-none/info.png");
      case "money":
        return require("@/assets/list-none/money.png");
      case "order":
        return require("@/assets/list-none/order.png");
      case "record":
        return require("@/assets/list-none/record.png");
      case "setting":
        return require("@/assets/list-none/setting.png");
      case "tag":
        return require("@/assets/list-none/tag.png");
    }
  }
  // 下拉刷新 / 导航栏选择
  onRefresh() {
    this.page = 0;
    this.$emit("clearList", []);
    this.getList();
  }
  // 获取列表请求 / 上拉加载
  getList() {
    let _this = this;
    this.loading = true;
    this.finished = false;
    this.$api.request({
      loading: "",
      url: this.url,
      data: {
        ...this.data,
        Page: this.page + 1
      },
      success(res) {
        let list = res.data.list;
        if (list.length === 0) {
          _this.finished = true;
        } else {
          _this.finished = false;
          _this.page++;
        }
        _this.isLoading = false;
        _this.loading = false;
        _this.$emit("getInfo", res.data);
        _this.$emit("setList", list);
        
      },
      fail() {
        _this.loading = false;
        _this.isLoading = false;
        _this.finished = true;
      }
    });
  }

  // 监听滚动高度
  scrollEvent(e) {
    let route = String(this.$route.name);
    this.$globalData.scroll_keep_alive[route] = e.srcElement.scrollTop;
  }
}
